import React, { useEffect, useRef, useState } from "react";
import { JigsawPuzzle } from "./jigsaw-controller";
import "./jigsaw-puzzle.css";
import Logo from "../assets/GK Logo.png";
import Confetti from "react-confetti";
import PuzzleImageContext from "../puzzleImageContext";
import { useContext } from "react";
import imageData from "../../src/assets/collection.json"
const palette = require("image-palette");
const pixels = require("image-pixels");


const JigSawPuzzle = () => {
  const { setPuzzleImageUrl } = useContext(PuzzleImageContext);
  const [puzzleImageSrc, setPuzzleImageSrc] = useState<string>("");
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isSolved, setIsSolved] = useState(false);
  const [puzzleKey, setPuzzleKey] = useState(0);
  const [showPuzzle, setShowPuzzle] = useState(true);
  const [rows, setRows] = useState(3); //default is 3
  const [columns, setColumns] = useState(3); //default is 3
  const [colors, setColors] = useState<string[]>([]);
  async function extractColorsFromImage(
    imageSrc: string,
    colorCount: number = 4
  ): Promise<string[]> {
    try {
      const pixelData = await pixels(imageSrc);
      const { colors } = palette(pixelData, colorCount);

      // Convert colors to CSS RGB format
      const cssColors = colors.map(
        (color: number[]) => `rgb(${color[0]}, ${color[1]}, ${color[2]})`
      );
      return cssColors;
    } catch (error) {
      console.error("Error extracting colors:", error);
      throw error;
    }
  }

  const handlePuzzleSolved = () => {
    setIsSolved(true);
    document.body.classList.add("puzzle-solved");
    document.body.classList.add("jigsaw-puzzle--solved");
    const puzzleBox = document.getElementById("puzzleBox");
    if (puzzleBox) {
      puzzleBox.classList.add("rainbow");
    }
    setTimeout(() => {
      setIsSolved(false);
      setShowPuzzle(false); // Hide puzzle
      document.body.classList.remove("puzzle-solved");
      document.body.classList.remove("jigsaw-puzzle--solved");
      const puzzleBox = document.getElementById("puzzleBox");
      if (puzzleBox) {
        puzzleBox.classList.remove("rainbow");
      }
      setTimeout(() => {
        setShowPuzzle(true); // Remount puzzle
        setPuzzleKey((prevKey) => prevKey + 1);
        setRows((prevRows) => prevRows + 1);
        setColumns((prevColumns) => prevColumns + 1);
      }, 100);
    }, 13000);
  };
  useEffect(() => {
    const canvas = canvasRef.current;

    if (canvas && canvas.getContext) {
      const ctx = canvas.getContext("2d");

      if (ctx) {
        const images = imageData.map((item: { name: any; }) => `assets/collection/${item.name}`);
      const randomIndex = Math.floor(Math.random() * images.length);
      const randomImage = require(`../${images[randomIndex]}`);

      const loadImage = (src: string): Promise<HTMLImageElement> => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = () => resolve(img);
          img.onerror = reject;
        });
      };

      Promise.all([
        loadImage(Logo),
        loadImage(randomImage),
      ])
          .then(([logo, backgroundImage]) => {
            const padding = 60;
            const maxCanvasWidth = canvas.parentElement?.clientWidth ?? 500;
            const aspectRatio = logo.naturalWidth / logo.naturalHeight;

            let canvasWidth = logo.naturalWidth + padding * 2;
            let logoWidth = logo.naturalWidth;
            let logoHeight = logo.naturalHeight;

            if (canvasWidth > maxCanvasWidth) {
              canvasWidth = maxCanvasWidth;
              logoWidth = maxCanvasWidth - padding * 2;
              logoHeight = logoWidth / aspectRatio;
            }

            const canvasHeight = logoHeight + padding * 2;

            canvas.width = canvasWidth;
            canvas.height = canvasHeight;
            // Draw the logo
            const x = (canvasWidth - logoWidth) / 2;
            const y = (canvasHeight - logoHeight) / 2;
            ctx.drawImage(logo, x, y, logoWidth, logoHeight);

            // Set the global composite operation
            ctx.globalCompositeOperation = "source-in";

            // Draw the background image and clip it to the logo
            ctx.drawImage(backgroundImage, 0, 0, canvasWidth, canvasHeight);
            ctx.globalCompositeOperation = "source-over";
            canvas.toBlob((blob) => {
              if (blob) {
                const url = URL.createObjectURL(blob);
                setPuzzleImageSrc(url);
                setPuzzleImageUrl(url);
              }
            });
          })
          .catch((error) => {
            console.error("Error loading images:", error);
          });
      }
    }
  }, [puzzleKey]);
  useEffect(() => {
    if (isSolved) {
      const timer = setTimeout(() => {
        setIsSolved(false);
        setPuzzleKey((prevKey) => prevKey + 1);
      }, 15000);
      return () => clearTimeout(timer);
    }
  }, [isSolved]);
  useEffect(() => {
    const boxElements = document.getElementsByClassName("jigsaw-puzzle");
    const handleTouchMove = (event: Event) => {
      const touchEvent = event as TouchEvent;
      touchEvent.preventDefault();
    };
    Array.from(boxElements).forEach((element) => {
      (element as HTMLElement).addEventListener("touchmove", handleTouchMove, {
        passive: false,
      });
    });
    return () => {
      Array.from(boxElements).forEach((element) => {
        (element as HTMLElement).removeEventListener(
          "touchmove",
          handleTouchMove
        );
      });
    };
  }, []);
  useEffect(() => {
    if (puzzleImageSrc) {
      extractColorsFromImage(puzzleImageSrc)
        .then(setColors)
        .catch(console.error);
    }
  }, [puzzleImageSrc]);

  const puzzleStyle = {
    width: "100vw",
    height: "auto",
    margin: "0 auto",
    maxWidth: "700px",
    overflow: "hidden",
    touchAction: "none",
  };
  const dynamicStyles = {
    "--color1": colors.length > 0 ? colors[0] : "transparent",
    "--color2": colors.length > 0 ? colors[1] : "transparent",
    "--color3": colors.length > 0 ? colors[2] : "transparent",
    "--color4": colors.length > 0 ? colors[3] : "transparent",
  };
  const combinedStyle = {
    ...puzzleStyle,
    ...dynamicStyles,
  };
  const handleContextMenu = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
  };
  return (
    
    <div
    id="puzzleBox"
    className="rainbow shadow-md pb-2 px-2 sm:px-2"
    style={isSolved ? combinedStyle : puzzleStyle}
    onContextMenu={handleContextMenu}
  >
      {puzzleImageSrc && showPuzzle && (
        <div>
        <JigsawPuzzle
          key={`puzzle-${puzzleKey}`}
          imageSrc={puzzleImageSrc}
          rows={rows}
          columns={columns}
          onSolved={handlePuzzleSolved}
        />
</div>
      )}
      <canvas ref={canvasRef} style={{ display: "none" }} />
      {isSolved && <Confetti />}
    </div>
    
  );
};

export default JigSawPuzzle;
