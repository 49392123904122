import { createContext, Dispatch, SetStateAction } from 'react';

type PuzzleImageContextType = {
  puzzleImageUrl: string;
  setPuzzleImageUrl: Dispatch<SetStateAction<string>>;
};

const PuzzleImageContext = createContext<PuzzleImageContextType>({
  puzzleImageUrl: '',
  setPuzzleImageUrl: () => {},
});

export default PuzzleImageContext;
