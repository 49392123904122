import React from "react";
import { FaBriefcase } from 'react-icons/fa';
import { SectionProps, ProjectShowcaseProps } from "../interfaces/interface";

// Project Showcase Component
function ProjectShowcase({ project, projects }: ProjectShowcaseProps) {
  if (project) {
    return (
      <div className="flex items-center justify-center min-h-screen rounded-lg shadow-md overflow-hidden p-4">
        <div>
          <div className="relative h-60 w-full">
            <img
              src={project.imageUrl}
              alt={project.title}
              className="h-60 w-full object-cover"
            />
            <div className="absolute inset-0 bg-black opacity-10"></div>
          </div>
          <div className="bg-mc-200 p-5 pb-6 rounded-bl-lg rounded-br-lg">
            <h3 className="text-mc-950 text-4xl font-semibold mb-2">{project.title}</h3>
            <p className="text-charcoal text-xl mb-4">{project.description}</p>
            <a
              href={project.link}
              target="_blank"
              rel="noopener noreferrer"
              className="w-full border-2 text-center py-2 px-6 rounded-lg transition-all duration-200 ease-in-out transform active:scale-95"
              style={{ color: "rgb(232, 80, 14)", borderColor: "rgb(232, 80, 14)" }}
            >
              Visit project
            </a>
          </div>
        </div>
      </div>
    );
  }

  // Desktop view: render grid of projects
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 py-6">
      {projects?.map((proj, index) => (
        <div key={index} className="bg-mc-200 rounded-lg shadow-md overflow-hidden">
          <div>
            <div className="relative h-60 w-full">
              <img
                src={proj.imageUrl}
                alt={proj.title}
                className="h-60 w-full object-cover"
              />
              <div className="absolute inset-0 bg-black opacity-10"></div>
            </div>
            <div className="p-5 pb-6">
              <h3 className="text-mc-950 text-3xl font-semibold mb-2">{proj.title}</h3>
              <p className="text-mc-950 text-sm mb-4">{proj.description}</p>
              <a
                href={proj.link}
                target="_blank"
                rel="noopener noreferrer"
                className="w-full border-2 text-center py-2 px-6 rounded-lg transition-all duration-200 ease-in-out transform active:scale-95"
                style={{ color: "rgb(232, 80, 14)", borderColor: "rgb(232, 80, 14)" }}
              >
                Visit project
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ProjectShowcase;