import React from "react";
import Github from "../assets/Github.svg";
import Linkedin from "../assets/Linkedin.svg";

export default function Links() {
  return (
    <div className="p-4 flex flex-col items-center space-y-4">
      <a
        href="https://github.com/GorgonUK"
        target="_blank"
        rel="noopener noreferrer"
        className="transition-transform duration-300 ease-in-out transform hover:scale-110"
      >
        <img
          src={Github}
          alt="GitHub"
          className="w-16 h-16 text-mc-200 fill-current hover:text-gray-200"
        />
      </a>
      <a
        href="https://www.linkedin.com/in/georgekontus"
        target="_blank"
        rel="noopener noreferrer"
        className="transition-transform duration-300 ease-in-out transform hover:scale-110"
      >
        <img
          src={Linkedin}
          alt="LinkedIn"
          className="w-16 h-16 text-mc-200 fill-current hover:text-gray-200"
        />
      </a>
    </div>
  );
}