import React, { useEffect, useState } from 'react';
import Lottie from 'lottie-react';

const Thinking = () => {
  const [animationData, setAnimationData] = useState(null);

  useEffect(() => {
    const fetchAnimationData = async () => {
      const response = await fetch('https://lottie.host/674742d8-605b-4729-b383-5c7ed038e2a3/4oynS5ZjVv.json');
      const data = await response.json();
      setAnimationData(data);
    };

    fetchAnimationData();
  }, []);

  if (!animationData) return <div>Loading...</div>;

  return (
    <div className="animation-container">
      <Lottie 
        animationData={animationData} 
        loop={true}
        style={{ height: 300, width: 300 }}
      />
    </div>
  );
};

export default Thinking;