import { Project } from "../../interfaces/interface";
import JobHunt from "../../assets/JobHuntUK.png";
import SelectAI from "../../assets/SelectAI.png";
import ModernDropzone from "../../assets/ModernDropzone.png";
export const projects: Project[] = [
  {
    title: "Job Hunt UK",
    description:
      "Innovative job search application designed specifically for individuals seeking employment opportunities in their local area.",
    imageUrl: JobHunt,
    link: "https://jobhuntuk.com/",
  },
  {
    title: "Select AI",
    description:
      "Enhance browsing with Select AI. Select AI, the ultimate Chrome extension designed to enhance your browsing experience.",
    imageUrl:
      SelectAI,
    link: "https://chromewebstore.google.com/detail/select-ai/akencabglgnbjflmlmflddmocjbeghld",
  },
  {
    title: "Modern Dropzone",
    description:
      "A control to upload files using a modern interface. It integrates perfectly with tables that have notes enabled.",
    imageUrl: ModernDropzone,
    link: "https://pcf.gallery/modern-dropzone-pcf/",
  },
  ];