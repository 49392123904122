import React, { useState, useEffect} from "react";
import George from "../assets/George.png";
import Links from "./Links";

export default function AboutMe() {
  const [showLinks, setShowLinks] = useState(false);
  const [animationKeys, setAnimationKeys] = useState<number[]>([]);

  const handleToggleLinks = () => {
    setShowLinks((prev) => !prev);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimationKeys((prevKeys) => [...prevKeys, Date.now()]);

      setTimeout(() => {
        setAnimationKeys((prevKeys) => prevKeys.slice(1));
      }, 2000);
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  return (
    <div className="relative w-full max-w-[600px] mx-auto p-4 group" style={{ perspective: "1000px" }}>
      <div className="relative rounded-lg">
        {animationKeys.map((key) => (
          <div
            key={key}
            className="absolute inset-0 z-0 rounded-lg pointer-events-none"
            style={{
              transform: window.innerWidth < 768
                ? "scaleX(1.05) scaleY(0.6)"
                : "scaleX(1.04) scaleY(0.5)",
              transition: "transform 0.5s ease-out",
            }}
          >
            <div className="absolute inset-0 h-full w-full border-l-2 border-r-2 border-mc-200 opacity-100 animate-fade"></div>
            <div className="absolute inset-0 h-full w-full border-l-2 border-r-2 border-mc-200 opacity-50 animate-fade"></div>
            <div className="absolute inset-0 h-full w-full border-l-2 border-r-2 border-mc-200 opacity-0 animate-fade"></div>
          </div>
        ))}

        <main
          onClick={handleToggleLinks}
          className="relative z-10 cursor-pointer rounded-lg shadow-md bg-mc-200 overflow-visible transition-shadow duration-300 hover:shadow-lg"
        >
          <div className="flex flex-col sm:flex-row">
            {/* Image Section */}
            <div
              className="flex-shrink-0 w-full sm:w-1/3 h-60 sm:h-auto bg-cover bg-center rounded-tl-lg rounded-tr-lg sm:rounded-tr-none sm:rounded-bl-lg"
              style={{ backgroundImage: `url(${George})` }}
            ></div>

            {/* Text Section with Padding */}
            <div className="flex-grow flex flex-col justify-center p-6 lg:p-8">
              <h1 className="text-mc-950 mb-2 text-left text-4xl font-semibold tracking-tight">
                <span>Hello,</span>
                <span className="block">I'm George Kontus</span>
              </h1>
              <h2 className="text-2xl text-pretty text-charcoal mb-4 text-left tracking-normal">
                Senior D365 Developer
              </h2>
            </div>
          </div>
        </main>
      </div>

      {/* Conditional Rendering of Links Component */}
      {showLinks && (
        <div className="top-full mt-4 w-full">
          <Links />
        </div>
      )}
    </div>
  );
}