import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import PuzzleImageContext from "../puzzleImageContext";
import Logo from "../assets/GK Logo.png";

export default function Navbar() {
  const { puzzleImageUrl } = useContext(PuzzleImageContext);
  const navigate = useNavigate();
  
  const handleHomeClick = () => {
    navigate("/");
  };

  const userIsLoggedIn = false;

  return (
    <div className="fixed top-0 left-0 h-full w-16 flex flex-col justify-between items-center bg-transparent">
      {/* Logo Section */}
      <div className="p-4 flex flex-col items-center space-y-2">
        {!userIsLoggedIn && (
          puzzleImageUrl && (
            <img
              src={puzzleImageUrl}
              alt="Logo"
              className="w-9 h-9 cursor-pointer"
              onClick={handleHomeClick}
            />
          )
        )}
        {userIsLoggedIn && (
          <img
            src={Logo}
            alt="Logo"
            className="w-8 h-8 cursor-pointer"
            onClick={handleHomeClick}
          />
        )}
      </div>

      {/* Spacer Section (center-aligned items, if any) */}
      <div className="flex-grow flex items-center justify-center">
        {/* Add additional center content here, if needed */}
      </div>
    </div>
  );
}