// Contact.js
import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  interface FormData {
    name: string;
    email: string;
    message: string;
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('form-name', 'contact');
    formDataToSend.append('name', formData.name);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('message', formData.message);

    try {
      const response = await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(formDataToSend as any).toString(),
      });

      if (response.ok) {
        alert("Form successfully submitted");
        setFormData({ name: '', email: '', message: '' });
      } else {
        throw new Error("Form submission failed");
      }
    } catch (error) {
      alert("There was an issue submitting the form.");
    }
  };
  return (
    <div className="h-screen flex items-center justify-center">
      <div className="form-card bg-mc-200 p-8 rounded-lg shadow-lg w-full max-w-2xl md:min-w-[400px]">
        <h2 className="text-center text-4xl font-bold text-mc-950 mb-6">Contact Form</h2>
        
        <form 
          name="contact" 
          method="POST" 
          data-netlify="true" 
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
          className="space-y-4"
        >
          <input type="hidden" name="form-name" value="contact" />

          <div className="relative">
            <label htmlFor="name" className="block  text-mc-950 mb-2">Your Name</label>
            <InputText 
              id="name"
              name="name" 
              value={formData.name} 
              onChange={handleChange} 
              required 
              className="w-full p-3 border-2 rounded-md bg-transparent bg-mc-200 border-mc-950 text-mc-950"
            />
          </div>

          <div className="relative">
            <label htmlFor="email" className="block text-mc-950 mb-2">Email Address</label>
            <InputText 
              id="email" 
              name="email" 
              type="email"
              value={formData.email} 
              onChange={handleChange} 
              required 
              className="w-full p-3 border-2 rounded-md bg-transparent bg-mc-200 border-mc-950 text-mc-950"
            />
          </div>

          <div className="relative">
            <label htmlFor="message" className="block text-mc-950 mb-2">Your Message</label>
            <InputTextarea
              id="message" 
              name="message" 
              value={formData.message} 
              onChange={handleChange} 
              rows={5} 
              required 
              className="w-full p-3 border-2 rounded-md bg-transparent border-mc-950 text-mc-950"
            />
          </div>

          <div className="flex justify-center mt-6">
            <Button 
              label="Send Message" 
              icon="pi pi-send" 
              className="w-full border-2 text-center border-mc-600 text-mc-600 py-2 px-6 rounded-lg shadow-sm transition-all duration-200 ease-in-out transform active:scale-95"
              
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;